import { ImageProps } from 'next/image';
import React, { useMemo, useState } from 'react';

import { PLACEHOLDER_IMAGE } from '../constants/app';

export default function CustomNextImage({
    className,
    ...props
}: ImageProps & {
    className?: string;
}) {
    //
    const [isValidImg] = useState(true);

    const renderImage = useMemo(() => {
        let imgSrc = props.src;

        if (!props.src) {
            imgSrc = PLACEHOLDER_IMAGE;
        }

        if (typeof props.src == 'string') {
            if (props.src.startsWith('http') || props.src.startsWith('/')) {
                //do nothing
            } else {
                imgSrc = PLACEHOLDER_IMAGE;
            }
        }

        return (
            <img
                {...props}
                src={imgSrc as string}
                alt={props.alt ? props.alt : ''}
                className={`${className} ${isValidImg ? '' : 'bg-gray-300 opacity-30 dark:opacity-100 dark:bg-gray-700 rounded-md'}`}
            />
            // <Image
            //     {...props}
            //     src={isValidImg ? imgSrc : PLACEHOLDER_IMAGE}
            //     alt={props.alt ? props.alt : ''}
            //     className={`${className} ${isValidImg ? '' : 'bg-gray-300 opacity-30 dark:opacity-100 dark:bg-gray-700 rounded-md'}`}
            //     onError={() => setIsValidImg(false)}
            //     priority
            //     quality={60}
            // />
        );
    }, [isValidImg, className, props]);

    return <>{renderImage}</>;
}
