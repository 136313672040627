// app loading store
import { create } from 'zustand';

export type StoreType = {
    loading: boolean;
    setLoading: (newLoading: boolean) => void;
};

export const useLoadingStore = create<StoreType>()((set) => ({
    loading: false,
    setLoading: (status: boolean) => set({ loading: status }),
}));
